import { NgModule } from '@angular/core';
import { BtoaPipe, NotAvailablePipe, LongTextPipe } from './pipes';
import { TicketResponseStatusPipe } from './pipes/ticket-response-status.pipe';
import { TicketStatusPipe } from './pipes/ticket-status.pipe';
import { RemoveUnderscorePipe } from './pipes/removeUnderscore.pipe';
import { DurationFormatPipe } from './pipes/duration.pipe';
@NgModule({
	imports: [
	],
	providers: [
		// StorageService
	],
	declarations: [
		BtoaPipe,
		NotAvailablePipe,
		TicketResponseStatusPipe,
		TicketStatusPipe,
		LongTextPipe,
		DurationFormatPipe,
		RemoveUnderscorePipe,
	],
	exports: [
		RemoveUnderscorePipe,
		LongTextPipe,
		DurationFormatPipe,
		BtoaPipe,
		NotAvailablePipe,
		TicketResponseStatusPipe,
		TicketStatusPipe,

	]
})

export class SharedModule { }
