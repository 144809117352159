import { Injectable } from '@angular/core';
import { AngularFireDatabase  } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { take } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable()
export class MessagingService {

  notification: BehaviorSubject<any> = new BehaviorSubject(null);
  currentMessage = new BehaviorSubject(null);
  notificationToken;
  pageNumber = 0;
  constructor(
    private angularFireDB: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private fireStore: AngularFirestore,
    private angularFireMessaging: AngularFireMessaging) {
    this.angularFireMessaging.messaging.subscribe(
      (_messaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    );
  }

  setNotification(data) {
    this.notification.next(data);
  }
  get notificationList(): Observable<Array<any>> {
    return this.notification.asObservable();
  }

  GetData() {
    //console.log(this.pageNumber);
   if (this.pageNumber > 0) {
    this.angularFireDB.list('/notifications',
    ref => ref.limitToLast(this.pageNumber + 5)).valueChanges().subscribe(res => {
        //console.log(res.slice(this.pageNumber - 1));
      this.setNotification(res.slice(this.pageNumber - 1));
      return res;
      // //console.log(res)
    });
   }
  // return new Promise<any>((resolve, reject) => {
  //   this.angularFireDB.list('/notifications').subscribe(snapshots => {
  //     //console.log(snapshots)
  //     this.setNotification(snapshots);
  //     resolve(snapshots)
  //   })
  // })

  }

  /**
   * update token in firebase database
   *
   * @param userId userId as a key
   * @param token token as a value
   */
  updateToken(userId, token) {
    // we can change this function to request our backend service
    this.angularFireAuth.authState.pipe(take(1)).subscribe(
      () => {
        const data = {};
        data[userId] = token;
        this.angularFireDB.object('fcmTokens/').update(data);
      });
  }

  /**
   * request permission for notification from firebase cloud messaging
   *
   * @param userId userId
   */

  requestPermission(userId) {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        this.notificationToken = token;
        this.updateToken(userId, token);
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        //console.log('new message received. ', payload);
        this.currentMessage.next(payload);
      });
  }
}
