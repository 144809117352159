import { Component } from '@angular/core';
import { MessagingService } from './shared/messaging.service';
import { StorageService } from './shared';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})

export class AppComponent {
	message;
	constructor(private messagingService: MessagingService, public storage: StorageService
	) {
	}
	ngOnInit() {
		const userId = 'user001';
		this.messagingService.requestPermission(userId);
		this.messagingService.receiveMessage();
		this.message = this.messagingService.currentMessage;
		this.storage.checkAuth();
		//console.log(this.messagingService.GetData());

	}
}

