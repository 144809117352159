// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  // API_END_POINT: "https://bikeappstag.appskeeper.com/api/v1",
  // API_END_POINT: 'https://api.mooscooters.com/api/v1',
  // API_END_POINT: 'https://api.mooscooters.com/api/v1',
  API_END_POINT: 'https://bikeappapidev.appskeeper.in/api/v1',
  
  SOCKET_END_POINT: 'https://bikeappdev.appskeeper.com',
  firebase: {
    apiKey: 'AIzaSyB8EmNlnd-2jE7IG5yRMAOAZvNwbz-_Nok',
    authDomain: 'bluscooter-312ad.firebaseapp.com',
    databaseURL: 'https://bluscooter-312ad.firebaseio.com',
    projectId: 'bluscooter-312ad',
    storageBucket: 'bluscooter-312ad.appspot.com',
    messagingSenderId: '432118233122',
    appId: '1:432118233122:web:53a1aa498de49bcc'
  }
};
