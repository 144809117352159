import { FormGroup, FormBuilder, Validators, AbstractControl, FormArray } from '@angular/forms';

import * as APP_CONSTANT from '../constants/app.constant';
import * as appUtils from '../appUtils';

export class CustomValidator {
	// Validates email
	static emailValidator(control: AbstractControl): any {
		const email = control.get('email').value;
		const EMAIL_REGEXP = appUtils.convertStringToRegExp(APP_CONSTANT.REGEX.EMAIL);
		if (!email) {
			return null;
		}
		if (EMAIL_REGEXP.test(email)) {
			return null;
		} else {
			control.get('email').setErrors({ email: true });
		}
	}

	// Validates passwords
	static matchPassword(control: AbstractControl): any {
		const password = control.get('resetPassword').value;
		const confirmPassword = control.get('confirmPassword').value;
		if (password != confirmPassword) {
			control.get('confirmPassword').setErrors({ ConfirmPassword: true });
		} else {
			return null;
		}
	}
	// remove spaces
	static removeSpaces(control: AbstractControl) {
		if (control && control.value && !control.value.replace(/\s/g, '').length) {
			control.setValue('');
		}
		return null;
	}




	static compareValue(control: FormGroup) {

		// if (+control.controls['perMinRidePrice'].value <= +control.controls['maxRidePrice'].value) {
		// 	control.controls['maxRidePrice'].setErrors(null);
		// } else {
		// 	control.controls['maxRidePrice'].setErrors({ smallMaxRidePrice: true });
		// }
		if (+control.controls['maxRidePrice'].value >= parseInt(control.controls['perMinRidePrice'].value) + parseInt(control.controls['rideUnlockPrice'].value)) {
			control.controls['maxRidePrice'].setErrors(null);
		} else {
			control.controls['maxRidePrice'].setErrors({ smallMaxRidePrice: true });
		}

		if (+control.controls['chargeLevel'].get('10').value <= +control.controls['chargeLevel'].get('20').value) {
			control.controls['chargeLevel'].get('20').setErrors(null);
		} else {
			control.controls['chargeLevel'].get('20').setErrors({ smallUpToTwenty: true });
		}

		if (+control.controls['chargeLevel'].get('20').value <= +control.controls['chargeLevel'].get('30').value) {
			control.controls['chargeLevel'].get('30').setErrors(null);
		} else {
			control.controls['chargeLevel'].get('30').setErrors({ smallUpToThirty: true });
		}

		if (+control.controls['chargeLevel'].get('30').value <= +control.controls['chargeLevel'].get('40').value) {
			control.controls['chargeLevel'].get('40').setErrors(null);
		} else {
			control.controls['chargeLevel'].get('40').setErrors({ smallUpToFourty: true });
		}

		if (+control.controls['chargeLevel'].get('40').value <= +control.controls['chargeLevel'].get('50').value) {
			control.controls['chargeLevel'].get('50').setErrors(null);
		} else {
			control.controls['chargeLevel'].get('50').setErrors({ smallUpToFifty: true });
		}

		if (+control.controls['chargeLevel'].get('50').value <= +control.controls['chargeLevel'].get('60').value) {
			control.controls['chargeLevel'].get('60').setErrors(null);
		} else {
			control.controls['chargeLevel'].get('60').setErrors({ smallUpToSixty: true });
		}

		if (+control.controls['chargeLevel'].get('60').value <= +control.controls['chargeLevel'].get('70').value) {
			control.controls['chargeLevel'].get('70').setErrors(null);
		} else {
			control.controls['chargeLevel'].get('70').setErrors({ smallUpToSeventy: true });
		}

		if (+control.controls['chargeLevel'].get('70').value <= +control.controls['chargeLevel'].get('80').value) {
			control.controls['chargeLevel'].get('80').setErrors(null);
		} else {
			control.controls['chargeLevel'].get('80').setErrors({ smallUpToEighty: true });
		}

		if (+control.controls['chargeLevel'].get('80').value <= +control.controls['chargeLevel'].get('90').value) {
			control.controls['chargeLevel'].get('90').setErrors(null);
		} else {
			control.controls['chargeLevel'].get('90').setErrors({ smallUpToNinety: true });
		}

		if (+control.controls['chargeLevel'].get('90').value <= +control.controls['chargeLevel'].get('100').value) {
			control.controls['chargeLevel'].get('100').setErrors(null);
		} else {
			control.controls['chargeLevel'].get('100').setErrors({ smallUpToHundred: true });
		}

	}


}

