export const ValidationMessages = {
	EMAIL_REQUIRED: 'Email is required.',
	EMAIL_INVALID: 'Please enter a valid email.',
	PASSWORD_REQUIRED: 'Password is required.',
	PASSWORD_INVALID: 'Please enter a valid password.',
	PASSWORD_UNMATCH: 'Passsword and Confirm Password did not match.',
	MOBILE_REQUIRED: 'Mobile Number is required.',
	MOBILE_MIN_LENGTH: 'Mobile number must contain 6 digits.',
	MOBILE_MAX_LENGTH: 'Mobile number must not exceed 16 digits.',
	NAME: 'Name is required.',
	DATE_REQUIRED: 'This Field is Required',
	NAME_MAX_LENGTH: 'Name must not exceed 45 chars.',
	COUPON_MAX_LENGTH: 'Code must not exceed 10 chars.',
	TITLE_REQUIRED: 'Title is required.',
	TITLE_BLANK: 'Title can not be blank.',
	COMMENT_BLANK: 'Comment can not be blank.',
	TITLE_MAX_LENGTH: 'Title must not exceed 45 chars.',
	COMMENT_MAX_LENGTH: 'Comment must not exceed 500 chars.',
	STATUS_REQUIRED: 'Status is required.',
	TYPE_REQUIRED: 'Type is required.',
	DESCRIPTION_REQUIRED: 'Description is required.',
	LOCATION_MAX_LENGTH: 'Location Name must not exceed 90 chars.',
	LOCATION_BLANK: 'Location Name can not be blank.',
	LOCATION_REQUIRED: 'Location Name is required.',
	NUMBER_BETWEEN: 'Only number between 1 to 100 is allowed.',
	COUNTRY_REQUIRED: 'Country Name is required.',
	COUNTRY_MAX_LENGTH: 'Country name must not exceed 45 chars.',
	COUNTRYCODE_REQUIRED: 'Country Code is required.',
	COUNTRY_SHORTCODE_REQUIRED: 'Country Short Code is required.',
	REASON_REQUIRED: 'Reason is required.',
	REASON_MAX_LENGTH: 'Reason must not exceed 45 chars.',
	FILTER_FIELD_MINUTES_FROM: 'MinutesTo must be less then MinutesFrom.',
	FILTER_FIELD_CHARGER_FROM: 'Value must be greater then initial value.',
	FILTER_FIELD_BOOKED_FROM: 'Booked From must be less than Booked To.',
	FILTER_FIELD_RIDES_FROM: 'Rides From must be less than Rides To',
	COUNTRY_SETTING_DELETED_SUCESS: 'Country Setting deleted successfully.',
	PROFILE_UPDATED: 'Profile updated successfully.',
	PASSWORD_UPDATED: 'Password updated successfully.',


	REQUIRED: 'This field is required.',
	SETTINGS_ADDED: 'Configuration added successfully.',
	SETTINGS_UPDATED: 'Configuration updated successfully.',
	GREATER_PREVIOS_THEN_DEDUCTION: 'The value must be greater than No deduction distance.',
	GREATER_PREVIOS: 'Field is Required & must be greater than previous value.',
	GREATER_PREVIOS_Array: 'The value must be greater than previous value.',
	SOMETHING_WENT_WRONG: 'Something went wrong.',
	CONTENT_UPDATED: 'Content updated successfully.',
	RUNNING_SCOOTER: 'Scooter is on ride, you can not change the status.',
	CURRENT_STATUS: 'Scooter current status has been updated successfully.',
	TICKET_STATUS: 'Ticket status has been updated successfully.',
	SUB_ADMIN_DELETED_SUCCESS: 'Sub Admin deleted successfully',
	SUB_ADMIN_BLOCKED_SUCCESS: 'Sub Admin blocked successfully',
	SUB_ADMIN_ACTIVE_SUCCESS: 'Sub Admin unblocked successfully',
	ADD_SUB_ADMIN_SUCCESS: 'Sub Admin added successfully',
	ADD_SUB_ADMIN_ROLES_SUCCESS: 'Sub Admin roles added successfully',
	EDIT_SUB_ADMIN_SUCCESS: 'Sub Admin updated successfully',

	MIN_REDEEM: 'Redeem must be at least 1 user',
	MIN_REEDEEM_PER_USER: 'Redeem must be at least 1 per user',

	MAX_RIDE_EXPIRE_TIME: 'max ride expire time must be at leat 1 hours',

	MIN_BOOKING_PRICE: 'booking price must be atleast 0.1',
	MIN_PER_MIN_RIDE_PRICE: 'min per minutes ride price must be atleast 0.1',
};
