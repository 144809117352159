import { Injectable } from '@angular/core';
import { Router, CanActivate, CanLoad } from '@angular/router';

import { StorageService } from './storage.service';
import { DashboardService } from './dashboard.service';

@Injectable({providedIn: 'root'})

export class AuthGuardService implements CanActivate , CanLoad {

	constructor(
		private router: Router,
		private storageServiceHandler: StorageService,
		private dashboardServiceHandler: DashboardService
	) { }

	async canActivate() {
		if (this.storageServiceHandler.getToken()) {
			await this.storageServiceHandler.checkAuth();
			// logged in so return true
			return true;
		}

		// not logged in so redirect to login page with the return url
		// this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
		this.router.navigate(['/login']);
		return false;
	}
	async canLoad() {
		if (this.storageServiceHandler.getToken()) {
			// logged in so return true
			await this.storageServiceHandler.checkAuth();
			return true;
		}

		// not logged in so redirect to login page with the return url
		// this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
		this.router.navigate(['/login']);
		return false;
	}
}
