export let convertStringToRegExp = function(value) {
	return new RegExp(value);
};

export let convertRegExpToString = function(value) {
	return value.source;
};

export let convertMillisToEndOfTheDay = function(value: number) {
	// 1545868800000 to 1545955199999
	return value + 86399999;
};