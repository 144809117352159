import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

import * as APP_CONSTANT from '../../shared/constants/app.constant';
import { StorageService } from './storage.service';

@Injectable({providedIn: 'root'})

export class DashboardService {

	constructor(
		private storageServiceHandler: StorageService,
	) { }

	adminDetails() {
		if (this.storageServiceHandler.getToken()) {
			const helper = new JwtHelperService();
			const accessToken = this.storageServiceHandler.getToken(); 
			return helper.decodeToken(accessToken);
		}
	}

	isTokenExpired() {
		const helper = new JwtHelperService();
		const accessToken = this.storageServiceHandler.getToken();
		return helper.isTokenExpired(accessToken);
	}


}
