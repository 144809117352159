import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ErrorObservable} from 'rxjs-compat/observable/ErrorObservable';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private _http: HttpClient) {
  }
  get(url, queryParams?: any): Observable<any> {
    return this._http.get(environment.API_END_POINT + url, {params: queryParams})
        .pipe(
            map((data: any) => {
              return data;
            }),
            catchError(err => ErrorObservable.create(err))
        );
  }

  /**
   * Make POST request
   * @ param url
   * @ param body
   */
  post(url: string, body?: any) {
    return this._http
        .post(
            environment.API_END_POINT + url,
            body,
        )
        .pipe(
            map((data: any) => {
              return data;
            }),
            catchError(err => ErrorObservable.create(err))
        );
  }
  /**
   * Make PUT request
   * @ param url
   * @ param body
   */
  put(url: string, body?: any) {
    return this._http
        .put(
            environment.API_END_POINT + url,
            body,
        )
        .pipe(
            map((data: any) => {
              return data;
            }),
            catchError(err => ErrorObservable.create(err))
        );
  }

    /**
   * Make PATCH request
   * @ param url
   * @ param body
   */
  patch(url: string, body?: any) {
    return this._http
        .patch(
            environment.API_END_POINT + url,
            body,
        )
        .pipe(
            map((data: any) => {
              return data;
            }),
            catchError(err => ErrorObservable.create(err))
        );
  }


  delete(url: string) {
    return this._http
        .delete(
            environment.API_END_POINT + url
        )
        .pipe(
            map((data: any) => {
              return data;
            }),
            catchError(err => ErrorObservable.create(err))
        );
  }
}
