import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ticketResponseStatus'
})
export class TicketResponseStatusPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value == 1) {
      return 'New';
    } else if (value == 2) {
      return 'Respond';
    } else if (value == 3) {
      return 'Response Due';
    } else if (value == 4) {
      return 'Customer Responded';
    } else {
      return null;
    }
  }

}
