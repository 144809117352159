import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatDialogModule } from '@angular/material';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ChartModule } from 'angular-highcharts';
import { ToastrModule } from 'ngx-toastr';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { AuthGuardService } from './shared/services/auth-guard.service';
import { TokenInterceptorService } from './shared/services/token-interceptor.service';
import { LoaderModule } from './shared';
import { CommonDialogModule } from './layout/dialog/common-dialog/common-dialog.component';

// AoT requires an exported function for factories
export const createTranslateLoader = (http: HttpClient) => {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};
import { AngularFirestoreModule } from '@angular/fire/firestore';

import { AsyncPipe } from '../../node_modules/@angular/common';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { MessagingService } from './shared/messaging.service';
// import { AngularFirestoreModule } from 'angularfire2/firestore';
import {
	IMqttMessage,
	MqttModule,
	IMqttServiceOptions
  } from 'ngx-mqtt';
import { ExcelService } from './shared/services/excel.service';
export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
	hostname: 'appsin.appskeeper.com',
	port: 8080,
	path: '/mqtt',
	username: 'appinventiv',
	password: 'GHDSFFDWD12^FscC',
	protocol: 'wss',
  };

// export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
// 	hostname: 'mq.mooscooters.com',
// 	port: 8080,
// 	path: '/mqtt',
// 	username: 'bluemqtt',
// 	password: 'hijlkmlmcsadc',
// 	protocol:'wss'
// };


@NgModule({
	declarations: [
		AppComponent,
	],
	imports: [
		 MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
		AngularFireDatabaseModule,
		AngularFireAuthModule,
		AngularFireMessagingModule,
		AngularFireModule.initializeApp(environment.firebase),
		AngularFirestoreModule,
		CommonDialogModule,
		LoaderModule,
		BrowserModule,
		BrowserAnimationsModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		MatDialogModule,
		MatFormFieldModule,
		// AngularFireModule ,
		ChartModule,
		ToastrModule.forRoot({
			preventDuplicates: true,
			closeButton: true,
			progressBar: true,
			maxOpened: 1
		}),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient]
			}
		}),
		NgIdleKeepaliveModule.forRoot(),
		AppRoutingModule,
		SharedModule
	],
	providers: [
		MessagingService, AsyncPipe,
		AuthGuardService,
		ExcelService,
		{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
	],
	bootstrap: [AppComponent],
})

export class AppModule { }
