import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuardService } from './shared/services/auth-guard.service';
import { PreloginGuard } from './shared/services/prelogin.guard';

const routes: Routes = [
	{ path: '', redirectTo: 'admin', pathMatch: 'full' },
	{ path: 'admin', loadChildren: './layout/layout.module#LayoutModule', canLoad: [AuthGuardService], canActivate: [AuthGuardService], },
	{ path: 'content-page', loadChildren: './content-page/content-page.module#ContentPageModule' },
	{ path: 'login', loadChildren: './login/login.module#LoginModule', canLoad: [PreloginGuard] , canActivate: [PreloginGuard]},
	{ path: 'reset-password', loadChildren: './change-forgot-password/change-forgot-password.module#ChangeForgotPasswordModule', canLoad: [PreloginGuard] , canActivate: [PreloginGuard] },
	{ path: 'not-found', loadChildren: './not-found/not-found.module#NotFoundModule' },
	{ path: '**', redirectTo: 'not-found' }
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
	exports: [RouterModule]
})

export class AppRoutingModule { }
