import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'longTextFilter'
})

export class LongTextPipe implements PipeTransform {

	transform(value: any): any {
		if (value && value.length >= 40) {
			value = value.substr(0, 40);
			return value += '....';
		}
		return value;
	}
}
