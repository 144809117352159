import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import * as APP_CONSTANT from '../../shared/constants/app.constant';
import { StorageService } from './storage.service';
import { map, catchError } from 'rxjs/operators';
import { LoaderService } from './loader.service';
import { ValidationMessages } from '../constants/messages';
import { NgxToastrService } from './ngx-toastr.service';
import { Router } from '@angular/router';

@Injectable()

export class TokenInterceptorService implements HttpInterceptor {

	constructor(
		private injector: Injector,
		private storageServiceHanlder: StorageService,
		private loaderService: LoaderService,
		private toastrService: NgxToastrService,
		private router: Router
	) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		this.showLoader();
		// add authorization header with jwt token if available
		request = request.clone({
			setHeaders: {
				authorization: this.storageServiceHanlder.getToken() ? 'Bearer ' + this.storageServiceHanlder.getToken() : APP_CONSTANT.BASIC_AUTH,
				platform: String(APP_CONSTANT.PLATFORM.WEB),
				api_key: APP_CONSTANT.API_KEY
			}
		});

		return next.handle(request).pipe(
			map((event: HttpEvent<any>) => {
				if (event instanceof HttpResponse) {
					this.onEnd();
				}
				return event;
			}),
			catchError((err: HttpErrorResponse) => {
				this.onEnd();
				const error = (err.error.message || err.statusText) ? ValidationMessages.SOMETHING_WENT_WRONG : '';
				this.toastrService.showError(err.error.message, 'Error');
				if (err.error.statusCode === 410 || err.error.responseType === 'Unauthorized') {
					this.storageServiceHanlder.setToken('');
					this.storageServiceHanlder.unsetUserData();
					this.router.navigate(['/login']);
				}
				return throwError(error);
			})
		);
	}

	private onEnd(): void {
		this.hideLoader();
	}

	private showLoader(): void {
		this.loaderService.show();
	}

	private hideLoader(): void {
		this.loaderService.hide();
	}
}
