

export const API_URL = {
	LOGIN: '/admin/login',
	FORGOT_PASSWORD: '/admin/forgot-password',
	VERIFY_TOKEN: '/admin/verify-token',
	CHANGE_FORGOT_PASSWORD: '/admin/reset-password',
	LOGOUT: '/admin/logout',
	ADMIN_ROLES: '/admin/check-permissoin',

	ADMIN_DETAILS: '/admin/profile',
	CHANGE_PASSWORD: '/admin/change-password',
	EDIT_PROFILE: '/admin/edit-profile',

	USER_LIST: '/admin/user-list',
	BULK_UPDATE: '/admin/bulk-status-update',
	BLOCK_USER: '/admin/user-status',
	APPROVE_USER_DOC: '/admin/update-document-status',
	USER_DETAILS: '/admin/user-detail',
	DELETE_USER: '/user/',
	SEND_NOTIFICATION: '/admin-notification/send/',

	ADD_CONTENT: '/admin/add-content',
	CONTENT_LIST: '/admin/content-list',
	EDIT_CONTENT: '/admin/edit-content',
	CONTENT_DETAILS: '/admin/content-detail',
	CONTENT_VIEW: '/admin/content-view',
	BLOCK_CONTENT: '/content/block/',
	DELETE_CONTENT: '/content/',

	ADD_COUNTRY: '/admin/add-country',
	EDIT_COUNTRY: '/admin/edit-country',
	COUNTRY_LIST: '/admin/country-list',
	COUNTRY_DETAIL: '/admin/country-detail',

	ADD_SCOOTER: '/admin/add-scooter',
	EDIT_SCOOTER: '/admin/edit-scooter',
	LIST_SCOOTER: '/admin/scooter-list',
	QR_CODE: '/admin/qr-code',
	SCOOTER_DETAILS: '/admin/scooter-detail',
	CHARGERS_DETAILS: '/admin/scooter/charging-detail',
	ACTIVE_COUNTRIES: '/admin/active-countries',
	UPDATE_SCOOTER_STATUS: '/admin/scooter-status',
	ADD_SCOOTER_BY_CSV: '/admin/upload-scooter-csv',

	ADD_GEOFENCE: '/admin/add-polygon',
	EDIT_GEOFENCE: '/admin/edit-polygon',
	LIST_GEOFENCE: '/admin/polygon-list',
	DELETE_GEOFENCE: '/admin/delete-polygon',
	DETAILS_GEOFENCE: '/admin/polygon-detail',

	PAYMENT_LIST: '/admin/transactions',
	REFUND_TRANSACTION: '/admin/transactions/refund',

	TICKET_LIST: '/admin/ticket',
	TICKET_DETAILS: '/admin/ticket-detail',
	TICKET_COMMENT: '/admin/ticket/comment',
	TICKET_CREATE: '/admin/ticket',
	TICKET_EDIT: '/admin/ticket/comment',
	TICKET_STATUS: '/admin/ticket/status',
	TICKET_COMENT_DELETE: '/admin/ticket/comment',

	COUPON_LIST: '/admin/coupon/listing',
	COUPON_DETAILS: '/admin/coupon/details',
	COUPON_STATUS_UPDATE: '/admin/coupon/status/update',
	COUPON_HISTORY: '/admin/coupon/history',
	COUPON_ADD: '/admin/create/update/coupon',
	DROPOFF_ADD: '/admin/stands',
	DROPOFF_UPDATE: '/admin/stands',
	DROPOFF_LIST: '/admin/stands',
	DROPOFF_DETAIL: '/admin/stands/detail',
	DROPOFF_DELETE: '/admin/stands',

	LIST_RIDES: '/admin/ride-list',
	DETAILS_RIDES: '/admin/ride-details',

	LIST_HELP_TEXT: '/admin/help-topic',
	ADD_HELP_TEXT: '/admin/help-topic',
	EDIT_HELP_TEXT: '/admin/help-topic',
	DETAIL_HELP_TEXT: '/admin/help-topic/detail',
	DELETE_HELPTOPIC: '/admin/help-topic',

	DASHBOARD_COUNT: '/admin/get-total-count',
	USER_REGISTRATION_GRAPH: '/admin/user-registration-graph',
	USER_PLATFORM_GRAPH: '/admin/user-platform-graph',
	REVENUE_GRAPH: '/admin/revenue-graph',
	HEAT_MAP: '/admin/heat-map',
	HEAT_MAP_MQTT: '/redzone/heat/map',
	CURRENT_SCOOTER_DATA: '/admin/scooter-stats',


	RIDER_HISTORY_MANAGEMENT: '/admin/rider/rides-history',
	CHARGER_HISTORY_MANAGEMENT: '/admin/charger/charge-history',
	PICKUP_HISTORY_MANAGEMENT: '/admin/charger/pickup-history',
	ADD_SETTINGS: '/admin/add-setting',
	EDIT_SETTINGS: '/admin/edit-setting',
	DELETE_SETTINGS: '/admin/delete-setting',
	SETTINGS_DETAILS: '/admin/setting-detail',
	SETTING_COUNTRIES: '/admin/setting-countries',
	NOTIFICATION_FILTER_USERS: '/admin/filter-users',
	NOTIFICATION_LIST: '/admin/notification-list',
	NOTIFICATION_ADD: '/admin/bulk-notification',

	FILTER_ADMINS: '/admin/filter-subadmin',
	ASSIGN_TO_ADMIN: '/admin/ticket/assign',
	GET_CURRENCY: '/admin/country/currency',
	GET_DROP_OF_BOOKED: '/admin/stand-users',
};

export const BASIC_AUTH = 'Basic cmNjOnJjY0AxMjM=';

export const PLATFORM = {
	ANDROID: 1,
	IOS: 2,
	WEB: 3,
	ALL: 4
};

export const GENDER = {
	MALE: 'male',
	FEMALE: 'female',
	ALL: 'all'
};

export const API_KEY = '1234';

export const STATUS = {
	BLOCKED: 1,
	UN_BLOCKED: 2,
	DELETED: 3,
	REQUEST_UN_BLOCKED: 4
};

export const REGEX = {
	EMAIL: '[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,63}$',
	URL: '^(http?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?\'\\+&%$#=~_-]+))*$',
	SSN: '^(?!219-09-9999|078-05-1120)(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$', // US SSN
	ZIP_CODE: '^[0-9]{5}(?:-[0-9]{4})?$',
	PASSWORD: '(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=[^0-9]*[0-9]).{8,}', // Minimum 6 characters, At least 1 lowercase alphabetical character, At least 1 uppercase alphabetical character, At least 1 numeric character, At least one special character
	MOBILE_NUMBER: '^\d{6,16}$',
	check_blank: /.*\S.*/,
};
