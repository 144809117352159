import { Pipe, PipeTransform, NgModule } from '@angular/core';

@Pipe({
  name: 'ticketStatus'
})
export class TicketStatusPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value == 1) {
      return 'Open';
    } else if (value == 2) {
      return 'Resolved';
    } else if (value == 3) {
      return 'In Progress';
    } else if (value == 4) {
      return 'Discussed';
    } else {
      return null;
    }

  }

}
