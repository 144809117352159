import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
	providedIn: 'root'
})

export class NgxToastrService {

	constructor(private toastrService: ToastrService) { }

	showSuccess(success, message) {
		this.toastrService.success(success, message);
	}

	showError(error, message) {
		this.toastrService.error(error, message);
	}

}
