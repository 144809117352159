import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'notAvailable'
})

export class NotAvailablePipe implements PipeTransform {

	transform(value: string): any {
		let val;
		if (value) {
			val = value.toString().trim();
		} else {
			val = value;
		}

		if (!val || val == 'null null') {
			return 'N/A';
		} else {
			return val;
		}
	}
}
