import {Component, Inject, OnInit, NgModule} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

import { CommonModule } from '@angular/common';

export interface DialogData {
    status: string;
    title: string;

}

@Component({
    selector: 'app-common-modal',
    templateUrl: './dialog-block.component.html',
    styleUrls: ['./dialog-block.component.scss']
})

export class CommonDialogComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<CommonDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
    }

    ngOnInit() {
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    onClick(): void {
        this.dialogRef.close(true);
    }
}

@NgModule({
	imports: [ CommonModule],
	exports: [CommonDialogComponent],
	declarations: [CommonDialogComponent],
	entryComponents: [CommonDialogComponent]
})

export class CommonDialogModule {

}
