import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';
import { DashboardService } from './dashboard.service';

@Injectable({
  providedIn: 'root'
})
export class PreloginGuard implements CanActivate, CanLoad {
  constructor(
    private router: Router,
    private storageServiceHandler: StorageService,
    private dashboardServiceHandler: DashboardService
  ) { }

  canActivate() {
    if (!this.storageServiceHandler.getToken()) {
      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    // this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
    this.router.navigate(['/dashboard']);
    return false;
  }

  canLoad() {
    if (!this.storageServiceHandler.getToken()) {
      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    // this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
    this.router.navigate(['/dashboard']);
    return false;
  }
}
