import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { database } from 'firebase';
@Injectable({
	providedIn: 'root'
})

export class StorageService {
	private _userData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	private _menuData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	constructor(public auth: AuthService) { }

	get userData(): Observable<any> {
		return this._userData.asObservable();
	}

	unsetUserData() {
		this._userData.next(null);
	}
	setUserData(userData) {
		this._userData.next(userData);
	}

	get menuData(): Observable<any> {
		return this._menuData.asObservable();
	}

	unsetMenuData() {
		this._menuData.next(null);
	}
	setMenuData(menuData) {
		this._menuData.next(menuData);
	}


	setToken(accessToken: string) {
		if (accessToken) {
			localStorage.setItem('accessToken', accessToken);
		} else {
			localStorage.removeItem('accessToken');
		}
	}

	getToken() {
		return localStorage.getItem('accessToken') ? localStorage.getItem('accessToken') : '';
	}

	setRememberMe(value) {
		localStorage.setItem('isRemberMe', value);
	}

	getRememberMe() {
		return localStorage.getItem('isRemberMe');
	}

	async checkAuth() {

		if (this.getToken()) {
			const resp = await this.auth.adminDetails().toPromise();
			this.setUserData(resp['data']);
			if (resp.data.adminType == 'SUB_ADMIN') {
				const roles = resp['data']['permission'][0];
				// if (resp['result'].adminType === ADMIN_TYPE.superAdmin) {
				//   roles['adminType'] = resp['result']['adminType'];
				// }
				this.setMenuData(roles);
			} else {
				const roles = {
					riders: { view: true, edit: true, add: true, block: true, delete: true },
					chargers: { view: true, edit: true, add: true, block: true, delete: true },
					scooters: { view: true, edit: true, add: true, block: true, delete: true },
					countries: { view: true, edit: true, add: true, block: true, delete: true },
					ridesManagement: { view: true, edit: true, add: true, block: true, delete: true },
					dropoff: { view: true, edit: true, add: true, block: true, delete: true },
					geofence: { view: true, edit: true, add: true, block: true, delete: true },
					role: { view: true, edit: true, add: true, block: true, delete: true },
					ticket: { view: true, edit: true, add: true, block: true, delete: true },
					settings: { view: true, edit: true, add: true, block: true, delete: true },
					notification: { view: true, edit: true, add: true, block: true, delete: true },
					content: { view: true, edit: true, add: true, block: true, delete: true },
					coupon: { view: true, edit: true, add: true, block: true, delete: true },
					payment: { view: true, edit: true, add: true, block: true, delete: true },
				};
				this.setMenuData(roles);
			}
		} else {
		}
	}
}
