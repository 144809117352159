import { Injectable } from '@angular/core';

import * as APP_CONSTANT from '../../shared/constants/app.constant';
import { HttpService } from './http.service';
import { map } from 'rxjs/operators';
import { MessageService } from 'ngx-editor/app/ngx-editor/common/services/message.service';
import { MessagingService } from '../messaging.service';

@Injectable({ providedIn: 'root' })

export class AuthService {

	constructor(
		private _httpService: HttpService,
		private messageService: MessagingService
	) { }
	login(body) {
		////console.log(this.messageService.notificationToken + '---')
		body.token = this.messageService.notificationToken == 'undefined' ? 'hjsfkjdshfkjsdhfkhdsafkjdshfkashfdkjsdhfkjsahf' : this.messageService.notificationToken;
		return this._httpService.post(APP_CONSTANT.API_URL.LOGIN, body).pipe(
			map((data: any) => {
				return data;
			}),
		);
	}

	forgotPassword(body) {
		return this._httpService.post(APP_CONSTANT.API_URL.FORGOT_PASSWORD, body).pipe(
			map((data: any) => {
				return data;
			}),
		);
	}

	verifyToken(body) {
		return this._httpService.post(APP_CONSTANT.API_URL.VERIFY_TOKEN, body).pipe(
			map((data: any) => {
				return data;
			}),
		);
	}

	changeForgotPassword(body) {
		return this._httpService.post(APP_CONSTANT.API_URL.CHANGE_FORGOT_PASSWORD, body).pipe(
			map((data: any) => {
				return data;
			}),
		);
	}

	adminDetails() {
		return this._httpService.get(APP_CONSTANT.API_URL.ADMIN_ROLES, {}).pipe(
		  map(data => {
			if (data) {
			  return data;
			} else {
			}
		  })
		);
	  }

	logout() {
		const body = {
			token : 'Bearer ' + localStorage.getItem('accessToken')
		};
		return this._httpService.put(APP_CONSTANT.API_URL.LOGOUT, body).pipe(
			map((data: any) => {
				return data;
			})
		);
	}


	// login($params) {
	// 	$params = Object.assign({
	// 		url: APP_CONSTANT.API_URL.LOGIN,
	// 		data: {
	// 			email: $params.email,
	// 			password: $params.password
	// 		}
	// 	}, $params);
	// 	return this.requestServiceHandler.preparePostRequest($params);
	// }

	// forgotPassword($params) {
	// 	$params = Object.assign({
	// 		url: APP_CONSTANT.API_URL.FORGOT_PASSWORD,
	// 		data: {
	// 			email: $params.email
	// 		}
	// 	}, $params);
	// 	return this.requestServiceHandler.preparePostRequest($params);
	// }

	// verifyToken($params) {
	// 	$params = Object.assign({
	// 		url: APP_CONSTANT.API_URL.VERIFY_TOKEN,
	// 		data: {
	// 			token: $params
	// 		}
	// 	}, $params);
	// 	return this.requestServiceHandler.preparePostRequest($params);
	// }

	// changeForgotPassword($params) {
	// 	$params = Object.assign({
	// 		url: APP_CONSTANT.API_URL.CHANGE_FORGOT_PASSWORD,
	// 		data: {
	// 			isReset: $params.isReset,
	// 			token: $params.token,
	// 			resetPassword: $params.resetPassword,
	// 			confirmPassword: $params.confirmPassword,
	// 		}
	// 	}, $params);
	// 	return this.requestServiceHandler.preparePostRequest($params);
	// }

	// logout() {
	// 	var $params = Object.assign({
	// 		url: APP_CONSTANT.API_URL.LOGOUT
	// 	}, $params);
	// 	return this.requestServiceHandler.preparePutRequest($params);
	// }
}
