import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { LoaderComponent } from './loader.component';

@NgModule({
	imports: [
		CommonModule,
		MatProgressBarModule
	],
	declarations: [ LoaderComponent ],
	exports: [ LoaderComponent ]
})

export class LoaderModule { }
